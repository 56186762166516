import React from "react";

import "../../theme/variables.scss";
import "../../styles/Global.scss";
import "../../styles/Home.scss";



import HeaderV2 from "../components/HeaderV2/HeaderV2";
import PictureSlider from "../components/PictureSlider";
import Footer from "../components/Footer/Footer";
import ContactUsBtn from "../components/ContactUsBtn";

import SEO from "../components/SEO";
import {StaticImage} from "gatsby-plugin-image";



const Home: React.FC = () => {

  return (
      <div>
          <SEO title={"QCTesting | Construction Material Testing (Nata Accredited) "} description={"QCTesting is a Concrete, soils, and aggregate Material testing company that serves the sunshine coast (We are nata accredited laboratory)"} url={"www.QCTesting.au/"}
           keywords="aggregate, concrete, soil, testing, aggregate testing, concrete testing, soil testing, materials testing, construction materials testing"/>
          <HeaderV2/>
          <PictureSlider/>
          <div className={"HomeMain"}>
            <div className={"titles"}>
                <div className={"information"}>
                        <h1> Testing Needs </h1>
                            <p>
                                QCTesting understands clients require tests for different reasons. Whether the results are to prove raw material deposits, tender for supply of construction material,
                                classify soil, quality assurance program, to determine suitability of materials for specialised uses or to inform the design of civil
                                engineering works the QCTesting team is NATA accredited and happy to help.
                            </p>
                        <div className={"points"}>
                                <p> Our highly skilled team will provide good customer service and prompt attention to all projects. </p>
                                <p> The QCTesting facility complies with the requirements of ISO/IEC 17025:2005 The use of NATA accredited laboratory facilities and testing services is a requirement of most project specifications. </p>
                                <p> Our NATA accredited laboratory (accreditation no. 19751) in the field of construction materials testing </p>
                                <p> Our testing is conducted using state of the art equipment and experienced geotechnical technicians. </p>
                                <p> We guarantee our results and understand the importance of providing efficient and timely reporting. </p>
                                <p> Analysis of stockpile </p>
                        </div>
                        <StaticImage
                            loading={"lazy"}
                            className={"images"}
                            src={'../images/EqualAssurance.png'}
                            alt={"equalAssurance certified quality"}
                            placeholder={"blurred"}
                            objectFit={"contain"}
                        />
                </div>
                    <div className={"imagesFlex"}>

                            <StaticImage
                                loading={"lazy"}
                                className={"images"}
                                src={'../images/NataLogo.webp'}
                                alt={"Nata Accreditation"}
                                placeholder={"blurred"}
                                objectFit={"contain"}
                            />
                    </div>
            </div>
          <div /* Fix Position Items  */ >
              <ContactUsBtn className={"contactBtnPosition"} redirect={"Tel:0408623377"}/>
          </div>
          </div>
          <Footer/>
      </div>
  );
};

export default Home;
