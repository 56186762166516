import React from "react";
import '../../styles/Components/PictureSlider.scss';

import  {useStaticQuery, graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";


const PictureSlider = () => {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(0);


    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "Backgrounds"}}) {
                nodes {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920
                            height: 1080
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            quality: 80
                        )
                    }
                    name
                }
            }
        }
    `);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        resetTimeout();
        let g;
        g = window.setTimeout(
            () => {
                setIndex((prevIndex) =>
                    prevIndex === data.allFile.nodes.length - 1 ? 0 : prevIndex + 1
                )
            },
            3500
        );
        timeoutRef.current = g;
        return () => {
            resetTimeout();
        };
    }, [index]);
    return (
        <div className={"pictureSlider"}>
            <div className={"cropHeight"}>
                <div
                    className={"slideshowSlider"}
                    style={{transform: `translate3d(${-index * 100}%, 0, 0)`}}
                >
                    {data.allFile.nodes.map((data: any , index : number) => {
                        return(
                        <GatsbyImage
                            key={index}
                            className={"image"}
                            image={data.childImageSharp.gatsbyImageData}
                            alt={data.name}
                        />
                    )})}
                </div>
                <div className={"dot"}>
                    {data.allFile.nodes.map((_: any, idx : number) =>
                        <div key={idx}
                             className={`${"slideshowDot"} ${idx === index ? "active" : ''}`}
                             onClick={() => {
                                 setIndex(idx);
                             }} />
                    )}
                </div>
            </div>
            <div className={"boxShadow"}/>
        </div>
    )
};

export default PictureSlider;
